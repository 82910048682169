import React, {useEffect} from 'react';
import services from '../../services.json';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import GitHubIcon from '@mui/icons-material/GitHub';
import 'aos/dist/aos.css';
import AOS from 'aos';
import './style.css'
import Divider from '@mui/material/Divider';
import { Link } from 'react-router-dom';
import { BorderBottom } from '@mui/icons-material';




const Services = () => {

   useEffect(()=>{
    AOS.init({
      duration: 2000
    })
   },[])
  return (
    <>
    <Divider sx={{color: 'black'}} className='Divider' textAlign="center">
      <Typography className='aboutserv' variant='h4' sx={{ textAlign: 'center', padding: '5px', color: '#6559cc' }}>Our core services</Typography>
      </Divider>
      <Box className='project' sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap', gap: '20px', padding: '20px' }}>
        {services.map((service) => (
          <Card className='serviceCard' data-aos="fade-up" key={service.id} sx={{ maxWidth: 345, padding: '10px',boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.3)', boarderbottom: '1px solid black'  }}>
            <CardMedia
              component="img"
              alt={service.alt}
              height="140"
              image = {require(`${service.image}`)}
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {service.title}
              </Typography>
              <Typography variant="body1" color="text.secondary">
                {service.description}
              </Typography>
            </CardContent>
            <CardActions>
                 
            </CardActions>
                    <Divider sx={{color: 'black'}} className='Divider' textAlign="right">
                        <Link className='link' to="booking">
                            <Button id='serve' variant="contained" size="large" style={{backgroundColor: 'black'}}>Enquire Now</Button>
                        </Link>
                        </Divider>
          </Card>
        ))}
      </Box>
    </>
  );
}
export default Services;